.checkout {
  &__content {
    .cart-item {
      .cart-item {
        &__total {
          width: 13%;
        }
      }
    }
    &.left,
    .sms_options_container,
    .ship-method-group {
      margin-bottom: 1em;
    }
    &.left {
      .checkout-buttons-container {
        margin: 1em 0;
        float: #{$rdirection};
      }
    }
    .payment-properties {
      .payment_img {
        width: 37px;
        height: 24px;
      }
    }
  }
  .offer-code-panel {
    &__content {
      .offer-code {
        &__input {
          text-align: left;
          margin-top: 5px;
        }
      }
    }
  }
  .review-submit,
  .shipping_checkout_btn {
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    background: $color--white;
    z-index: 100;
  }
  .checkout-button--sticky {
    @include swap_direction(padding, 0 20px);
    color: $color--white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    text-align: center;
    height: 60px;
    line-height: 4.3;
    display: inline-block;
    background-color: $color--black;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    @if $fonts_update == false {
      font-family: $ano-bold_regular-font;
    }
  }
  .sticky-checkout-button {
    display: flex;
    justify-content: center;
  }
  #recommended-products-panel {
    .recommended-product-items {
      .recommended-item {
        .description {
          min-height: 126px;
        }
        .formatted_price {
          position: relative;
          bottom: 0;
        }
      }
    }
    .checkout-header {
      width: 100%;
    }
  }
  .offer-code {
    &__submit {
      .button {
        width: 172px;
      }
    }
  }
  .cross_sell_container {
    width: 100%;
    padding-top: 10px;
    .cart_cross_sell_item {
      float: #{$ldirection};
      width: 50%;
      text-align: center;
      min-height: 200px;
    }
  }
  .site-container {
    .viewcart-panel__content {
      .cart-item {
        &__desc-info {
          .cart-item {
            &__price,
            &__remove-form {
              float: none;
            }
            &__product-price {
              .discount-price {
                margin: 10px 0;
              }
            }
          }
          .cart-item__qty {
            margin: 0;
          }
        }
      }
    }
    .payment-properties {
      .checkedEmpty {
        border: 1px solid $color--error;
      }
    }
    .picker-element {
      &.checkedEmpty {
        ~ div {
          label {
            color: $color--error;
            a {
              color: $color--error;
            }
          }
        }
      }
    }
  }
  &.viewcart {
    .sticky-checkout-button {
      .continue-checkout {
        margin-top: 0;
        font-size: 18px;
      }
    }
    .checkout-header {
      @include breakpoint($bp--large-up) {
        border: none;
        width: 75%;
      }
      &__title {
        width: 100%;
        padding: 14px 0 12px;
        margin: 0;
      }
    }
  }
  .viewcart-panel {
    border-bottom: 1px solid $color--gray--lightest;
    padding: 1em 0;
    ul#form--errors {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
  #top-viewcart-buttons {
    .continue-buttons {
      text-align: center;
      .go-shopping {
        width: 228px;
      }
    }
  }
  #offer-code-panel {
    .offer-code {
      &__one-offer-only {
        display: block;
      }
    }
  }
  &.shipping {
    .shipping-address-info {
      .address_controls {
        float: #{$ldirection};
        width: 50%;
        .text_link {
          display: block;
          border-bottom: none;
          text-decoration: underline;
        }
      }
      .address-container {
        float: #{$ldirection};
        width: 300px;
        word-wrap: break-word;
      }
    }
  }
  &.payment,
  &.review {
    .trans_detail_item {
      @include breakpoint($bp--medium-up) {
        float: #{$ldirection};
        width: 300px;
        margin-#{$rdirection}: 10px;
        word-wrap: break-word;
        margin-bottom: 20px;
      }
      h4 {
        @include breakpoint($bp--medium-up) {
          font-size: 2rem;
          float: #{$ldirection};
        }
      }
      .change_link {
        float: #{$rdirection};
        margin-#{$rdirection}: 20px;
        margin-top: 7px;
      }
    }
  }
  &.review {
    .review-panel {
      .gift-options-display,
      .shipping-address-display {
        .checkout__subtitle {
          border-bottom: 1px solid $color--gray--lightest;
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
  &__sidebar {
    .cart-item {
      &__select__container {
        .qty {
          select {
            border-width: 0;
          }
        }
      }
    }
  }
  &#payment_select {
    .site-container {
      #order-summary-panel {
        background: $color--gray--white;
        padding: 1em;
        @include breakpoint($bp--medium-up) {
          background: none;
        }
      }
    }
  }
  .confirmation-panel {
    &__content {
      border-top: 1px solid $color--gray--lightest;
    }
    .checkout {
      &__panel-title {
        margin-top: 0;
        padding-top: 15px;
      }
    }
    .checkout-header {
      &__title {
        width: 100%;
        padding-#{$ldirection}: 0;
      }
    }
  }
  .signin {
    .checkout-header {
      border: none;
    }
    .forgot-password {
      text-align: #{$rdirection};
      margin-bottom: 10px;
    }
  }
  .payment-properties {
    select {
      border-width: 0;
    }
  }
  .checkout-buttons {
    font-size: 18px;
    line-height: 3.5;
  }
  &__new-account,
  &__return-user {
    @include breakpoint($bp--large-up) {
      min-height: 350px;
      .new-account {
        &__submit {
          position: relative;
          top: 70px;
        }
      }
      .return-user {
        &__submit {
          position: relative;
          top: 15px;
        }
      }
    }
  }
  &__new-account {
    header {
      margin-bottom: 10px;
    }
  }
  &__return-user {
    header {
      margin-bottom: 45px;
    }
    &__toggle {
      .link {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .signin-panel {
    .new-account {
      .guest-confirmation {
        margin-bottom: 10px;
      }
    }
  }
  .site-header {
    .site-navigation {
      .checkout-navigation {
        &__menu {
          &.site-navigation {
            &__menu {
              @include breakpoint($bp--largest-up) {
                display: none;
              }
            }
          }
        }
      }
      .site-navigation {
        &__menu {
          @include breakpoint($bp--largest-up) {
            display: block;
          }
        }
      }
    }
  }
  .cr21-refresh {
    .site-my-mac-v1 {
      .picker-label {
        padding: 5px 0;
      }
      .picker-radio {
        margin-top: 10px;
      }
    }
  }
}

.samples-page {
  .samples {
    .samples-panel {
      border: none;
      &__content {
        top: auto;
        @include breakpoint($bp--medium-up) {
          top: -43px;
        }
        .product__desc {
          .sample-select-checkbox {
            .sample-select-button {
              padding: 0;
              width: 125px;
            }
          }
        }
        .sample-products {
          .choose-sample {
            .sample-select-button {
              padding: 0;
              width: 120px;
            }
          }
        }
        div {
          &.offer {
            div {
              &:first-child {
                .remaining {
                  position: relative;
                  top: auto;
                  padding-top: 11px;
                  @include breakpoint($bp--medium-up) {
                    top: -124px;
                  }
                }
              }
            }
          }
        }
      }
      &__continue-checkout {
        &--button {
          .continue-shopping {
            &__button {
              font-size: 18px;
              padding: 0;
            }
          }
        }
      }
    }
    .checkout-header {
      padding: 0.5em 0 5em 0;
      h2 {
        &.samples-panel__title {
          padding: 0;
          width: auto;
        }
      }
    }
    .sample-select-container {
      .checkbox {
        float: #{$rdirection};
        .picker-handle {
          display: none;
        }
      }
    }
    .deactivate {
      .btn {
        &:hover {
          color: $color--black;
          text-decoration: none;
        }
      }
    }
  }
}

.address_form_container {
  .field-container {
    padding-top: 5px;
  }
}

.gift-card-fields {
  .form_element {
    margin-bottom: 1em;
  }
}

#shipping {
  .shipping-address-info {
    .address_controls {
      .radio {
        float: none;
        width: 100%;
      }
    }
  }
}

.review {
  .providing-email {
    display: inline-block;
  }
}

.klarna-gdpr {
  float: left;
  margin-top: 50px;
}
