/* country_picker_eu_v1 */
$bg--overlay--color: rgba(0, 0, 0, 0.85);

#countryPicker {
  width: 20%;
  li.country {
    text-align: #{$ldirection};
    width: 45%;
    float: #{$ldirection};
    margin-#{$ldirection}: 8px;
  }
  ul {
    .country-list,
    .country-list-offsite {
      margin: 0;
      padding: 0;
    }
  }
}
/* country_picker_formatter_v1 */

.current-lang {
  float: #{$ldirection};
}

ul {
  &#countryList,
  .country-list-offsite {
    padding: 0;
    list-style: none;
  }
}

#countryPickerLinkWrap {
  float: #{$rdirection};
  position: relative;
  &:hover {
    #countryPickerLink {
      z-index: 1000;
    }
    .gnav-item-content {
      visibility: visible;
      opacity: 1;
      display: inline-table;
      margin-top: 9px;
    }
  }
  .gnav-item-content {
    display: none;
    background-color: $white;
    text-align: center;
    border: 1px solid $black;
    margin-top: 10px;
    position: initial;
    #{$rdirection}: 0;
    #{$ldirection}: -11px;
    transition: all 0.1s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    width: 84px;
    padding-bottom: 20px;
    max-height: calc(100% - 100px);
    .country-list {
      .country {
        a {
          color: $black;
        }
      }
    }
  }
  #countryPickerLink {
    cursor: pointer;
    padding: 0.5em;
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;
    width: 84px;
    img {
      display: block;
    }
  }
}

#countryPickerLink {
  position: relative;
  margin-bottom: 0;
  img {
    display: block;
  }
  &.gnav-item-content {
    .gnav-item-v1 & {
      position: fixed;
      background-color: $white;
      text-align: center;
      min-width: 320px;
      border: 1px solid $black;
    }
  }
}
/* locale_switch_warning_v1 */

#localeSwitchWarning {
  background: $white;
  margin: auto;
  font-size: 14px;
  line-height: 2em;
  width: 30%;
  padding: 2em;
  text-align: center;
  position: relative;
  .column {
    float: #{$rdirection};
  }
  .btn-secondary {
    background: $white;
    color: $black;
  }
}
/* locale_interstitial_v1 */

#localeOverlay,
#localeSwitchOverlay {
  background: $bg--overlay--color;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  position: fixed;
}

#localeInterstitial,
#localeSwitchOverlay {
  .column {
    padding: 1em;
    float: #{$ldirection};
  }
}

#localeInterstitial {
  background: $white;
  margin: auto;
  line-height: 2em;
  padding: 2px;
  border: 3px solid $black;
  text-align: center;
  width: 80%;
  max-width: 800px;
  .initial-hide {
    display: none;
  }
  .country-link {
    display: inline-block;
    line-height: 2em;
    border-bottom-color: transparent;
    text-decoration: none;
    @include breakpoint($bp--xsmall-down) {
      @if $fonts_update == true {
        font-size: 13px;
      } @else {
        font-size: 14px;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  li {
    width: 33%;
    display: inline-block;
    float: none;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    @if $fonts_update == true {
      @include breakpoint($bp--medium-up) {
        font-size: 15px;
      }
    }
  }
  ul {
    font-size: 0; /* Fix for country options having whitespace between them */
    width: 100%;
    &.country-list,
    &.country-list-offsite {
      padding: 0;
      list-style-type: none;
      li {
        width: 33%;
      }
    }
  }
  .language-list {
    li {
      padding: 0;
      width: 30%;
      margin: 20px;
      @include breakpoint($bp--medium-up) {
        width: 30%;
      }
    }
  }
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
  .welcome-label {
    font-size: 20px;
    font-weight: normal;
  }
  .country-caption {
    margin: 15px 0 20px 0;
    .label {
      font-size: 12px;
    }
  }
  .language-caption {
    margin-top: 25px;
  }
  .inner-border {
    border: 1px solid $black;
    padding: 1em;
    @include breakpoint($bp--medium-up) {
      padding: 3em 6em;
    }
  }
}

.language-list {
  li {
    &.selected {
      font-weight: bold;
    }
  }
}

.current-country {
  .selected-lang {
    font-weight: bold;
  }
}

.country-link {
  &.selected {
    font-weight: bold;
  }
  border-bottom-color: transparent;
  text-decoration: none;
  &:hover {
    border-bottom-color: transparent;
    text-decoration: none;
  }
}

.choose-country {
  font-size: 12px;
  padding: 0.5em 0;
  margin: 0.5em 0;
  @if $fonts_update == true {
    @include breakpoint($bp--medium-up) {
      font-size: 15px;
    }
  }
}

#mobileCountryLink {
  display: inline-block;
  width: 40%;
  vertical-align: super;
  cursor: pointer;
  @include breakpoint($bp--medium-up) {
    display: none;
  }
}

#changeLocaleButton {
  float: #{$ldirection};
  margin-#{$ldirection}: 0.5em;
}

#cancelChangeLocaleButton {
  float: #{$rdirection};
  margin-#{$rdirection}: 0.5em;
}

#localeInterstitial,
#localeSwitchWarning {
  .column {
    width: 50%;
  }
}

#countryPickerWrap {
  text-align: center;
  padding: 20px;
  #countryPickerLinkWrap {
    display: none;
    @include breakpoint($bp--medium-up) {
      display: block;
    }
  }
  .languagePickerLink {
    display: none;
    @include breakpoint($bp--medium-up) {
      display: block;
    }
  }
  .current-lang,
  .current-country {
    &:after {
      border: 5px solid transparent;
      border-top-color: $white;
      content: ' ';
      position: absolute;
      top: 17px;
    }
  }
  .current-country {
    &:after {
      #{$rdirection}: 2px;
    }
  }
  .current-lang {
    &:after {
      #{$rdirection}: 12px;
    }
  }
}

.site-navigation {
  position: relative;
}

.site-header {
  &__menu {
    .site-navigation {
      width: 78%;
    }
  }
  .site-header__extra {
    @include breakpoint($bp--largest-up) {
      width: 5%;
    }
  }
  &__suffix {
    width: 22%;
  }
  #countryPickerWrap {
    position: absolute;
    top: 0;
    z-index: 1;
    #{$rdirection}: -137px;
    font-size: 11px;
    width: 70px;
    padding: 20px 7px;
    @include breakpoint($bp--xxlarge-up) {
      #{$rdirection}: -220px;
      font-size: 15px;
      padding: 14px 0 10px 3px;
      width: 175px;
    }
    .languagePickerLinkWrap {
      float: #{$rdirection};
      position: relative;
      &:hover {
        .languagePickerLink {
          z-index: 1000;
        }
        ul.language-list {
          visibility: visible;
          opacity: 1;
          display: inline-table;
          margin-top: 9px;
        }
      }
      ul.language-list {
        display: none;
        background-color: $white;
        border: 1px solid $black;
        position: initial;
        width: 75px;
        padding-bottom: 20px;
        li.language {
          float: #{$ldirection};
          margin: 10px 0 0 5px;
          a {
            color: $black;
            cursor: pointer;
          }
          &.current-lang {
            font-weight: bold;
          }
        }
      }
      .languagePickerLink {
        float: #{$ldirection};
        cursor: pointer;
        margin: 0 10px 0 5px;
        padding: 0.5em;
        width: 70px;
      }
    }
  }
}

.site-footer {
  div #localeOverlay,
  div #localeSwitchOverlay {
    display: none;
  }
  ul.language-list {
    display: none;
    width: 40%;
    padding-#{$ldirection}: 27px;
    li {
      display: none;
      &.current-lang {
        display: block;
      }
    }
  }
}

.country,
.language {
  .picker {
    .picker-label {
      position: relative;
      padding-#{$ldirection}: 26px;
      margin-bottom: 12px;
      opacity: 1;
      z-index: -9999999;
    }
  }
  .picker-radio {
    &.picker {
      .picker-handle {
        position: absolute;
        height: 15px;
        width: 15px;
      }
      &.checked {
        .picker-handle {
          background-color: $color--black;
          .picker-flag {
            border-color: $color--black;
            position: absolute;
            top: -3px;
            #{$ldirection}: -3px;
          }
        }
      }
    }
  }
}

.initial-hide {
  .btn-primary {
    &.locale_button {
      color: $color--black;
      background: none;
      text-decoration: underline;
      outline: 0;
      &:hover,
      &:focus {
        color: $color--black;
      }
    }
  }
}
