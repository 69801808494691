.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-size {
        @include swap_direction(margin, 0 0 0 6px);
        font-size: 14px;
      }
    }
  }
}
