@if $password_strengthen {
  .password-reset-page {
    .password-reset {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            &-reset {
              top: -20px;
            }
          }
        }
      }
    }
  }
  .profile-password-update__fieldset {
    @include breakpoint($bp--small-down) {
      .js-label-mode {
        & + label {
          -webkit-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          -moz-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          -o-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--black !important;
        &.pass {
          color: $color--green !important;
        }
      }
    }
  }
}
