.site-email-signup {
  &__link-icon {
    line-height: 3.5;
  }
}

.page--spp {
  &__product {
    .product {
      &__detail {
        .product_content_fav_mobile {
          .product__price {
            float: none;
          }
        }
      }
    }
  }
}

.privacy-policy,
.terms-use,
.terms-sale {
  &__table {
    width: 100%;
    border: 1px solid $color--black;
    margin-bottom: 1.5em;
  }
  td {
    border: 1px solid $color--black;
  }
  .site-container & {
    padding: 0 10px;
  }
}

.terms-use,
.terms-sale {
  th {
    border: 1px solid $color--black;
    text-align: center;
  }
}

a {
  .icon--bars {
    line-height: 4;
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          width: 95%;
          height: 95%;
          margin: 0 7px 5px 7px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  form#waitlist {
    .field {
      .email_input {
        float: #{$ldirection};
        width: 100%;
        input {
          width: 76%;
          padding: 12px;
        }
      }
      input {
        &.input-btn {
          padding: 11px 10px;
        }
      }
    }
  }
}

.site-container {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          height: 0;
          line-height: 0;
          a.notify_me {
            @include breakpoint($bp--medium-up) {
              position: absolute;
            }
          }
        }
      }
      .product {
        &__mini-detail {
          margin-bottom: 6px;
        }
      }
    }
  }
  .mpp-sort {
    &__container {
      &.select--plain {
        margin-top: 60px;
        @include breakpoint($bp--medium-up) {
          margin-top: 0;
        }
      }
    }
  }
  .page-products & {
    nav {
      &.sec-nav {
        height: 59px;
      }
    }
  }
  .order-details-page {
    &__content {
      .thumb {
        img {
          width: 124px;
          height: 163px;
        }
      }
    }
  }
  .product {
    &__footer {
      .product {
        &__inventory-status {
          .sold_out_btn {
            float: #{$ldirection};
            width: 100%;
          }
        }
      }
    }
  }
  .collection-detail-formatter {
    .prod_inv_status-2 {
      .product__footer {
        li.product__inv-status-item {
          @include breakpoint($bp--large-down) {
            height: auto;
            padding: 0;
            .notify-status {
              a.notify_me {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .product--teaser {
    &.product--collection-dual {
      &.product--not-shoppable {
        + .carousel-controls {
          background: transparent;
        }
      }
    }
  }
}

.site-header {
  .site-header {
    &__menu {
      @include breakpoint($bp--xxlarge-up) {
        width: 895px;
        margin-#{$ldirection}: 155px;
      }
    }
    &__prefix {
      @include breakpoint($bp--xxlarge-up) {
        width: 121px;
        margin-left: 12px;
      }
    }
  }
  li {
    &.site-navigation {
      &__item,
      &__more {
        padding: 0 20px;
      }
    }
  }
}

.write_a_review {
  &__container {
    #variant-selector {
      margin-bottom: 10px;
    }
    #pr-write {
      clear: #{$ldirection};
      .p-w-r {
        .pr-header-required {
          display: block;
        }
      }
    }
  }
}

.section-mac-pro {
  padding: 5px 10px;
}

.progress-step-index {
  .popup-main {
    .welcome-message {
      height: 300px;
      @include breakpoint($bp--medium-up) {
        height: 190px;
      }
    }
  }
}

body.toolbar-drawer {
  padding-top: 0;
}

.gdpr-info {
  width: 100%;
  display: inline-block;
}

.master-logo,
.ups-logo {
  margin-top: 15px;
}

.postnord-logo {
  @include breakpoint($bp--xlarge-up) {
    margin-top: 20px;
  }
}

.grid-container--5 {
  .grid--item {
    &:nth-of-type(5n + 2) {
      @include breakpoint($bp--medium-landscape) {
        margin-#{$ldirection}: 16%;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 17%;
      }
    }
    &:nth-of-type(5n + 3) {
      margin-#{$ldirection}: 38%;
    }
    &:nth-of-type(5n + 4) {
      @include breakpoint($bp--medium-landscape) {
        margin-#{$ldirection}: 56%;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 57%;
      }
    }
    &:nth-of-type(5n + 5) {
      @include breakpoint($bp--medium-landscape) {
        width: 32%;
      }
      @include breakpoint($bp--xxlarge-up) {
        width: 30%;
      }
    }
  }
}

.site-footer {
  &--top {
    .block {
      &--footer-extra {
        .social-links {
          li {
            @include breakpoint($bp--medium-landscape) {
              @include swap_direction(margin, 0 2% 0 0);
            }
            @include breakpoint($bp--xxlarge-up) {
              @include swap_direction(margin, 0 18px 0 0);
            }
          }
        }
      }
    }
  }
  .footer-social {
    &--mobile {
      margin: 0;
      .social-links {
        li {
          padding-#{$ldirection}: 10px;
        }
      }
    }
  }
}

.signup-thanks-page {
  text-align: center;
  .print-logo {
    border: none;
  }
  img {
    width: 275px;
    margin: 20px 0;
  }
}

.email-sms-signup-v3 {
  .form {
    &--newsletter {
      &__form-items {
        .form {
          &--txtfield {
            margin: 10px auto;
            text-align: center;
          }
        }
        .form-actions {
          &.form-wrapper {
            text-align: center;
            .newsletter--btn-submit {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.kit-container {
  @include swap_direction(padding, 90px 0 0 0);
  @include breakpoint($bp--medium-down) {
    @include swap_direction(margin, 0 0 0 17%);
    @include swap_direction(padding, 10px 0 0 0);
    clear: both;
  }
  .checkout__sidebar & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    clear: both;
  }
  .cart-item {
    &__desc-info {
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 20px 0);
      border-bottom: 1px solid #{$color--black};
      &:last-child {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 0 20px);
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      @include swap_direction(margin, 0);
    }
  }
}

.order-details-page {
  .virtual-bundling {
    .kit-container {
      @include swap_direction(margin, 0 0 0 17%);
      @include breakpoint($bp--medium-down) {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 0 0 0);
      }
    }
    .kit-item {
      border-bottom: none;
      .cart-item {
        &__total {
          display: none;
        }
      }
    }
    .cart-item {
      &__total {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 4.8% 0 0);
        @include breakpoint($bp--medium-down) {
          float: #{$ldirection};
        }
        float: #{$rdirection};
        width: auto;
      }
    }
  }
  .kit-container {
    padding: 9% 0 0 17%;
    .cart-item {
      &__desc-info {
        border-bottom: 1px solid $color--gray--lightest;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.custom-kit-formatter {
  &__overlay {
    .custom-kit-formatter {
      &__title {
        letter-spacing: 0;
      }
      &__overlay-close {
        &::before {
          content: 'x';
          font-size: 20px;
        }
      }
    }
  }
  &__landing-content & {
    &__content-wrapper {
      @include breakpoint($bp--medium-down) {
        top: 28%;
        #{$ldirection}: 52%;
      }
      top: 77%;
      #{$ldirection}: 71%;
    }
    &__align-top {
      top: 28%;
    }
    &__start-cta {
      @include breakpoint($bp--medium-down) {
        padding: 0 17px;
        height: 42px;
      }
      background: $color--white;
      color: $color--black;
      font-size: 15px;
    }
  }
}

.checkout {
  .kit-container {
    @include breakpoint($bp--medium-down) {
      margin: 0 0 0 35%;
      padding: 0;
    }
    padding: 10% 0 0 17%;
    .cart-item {
      &__desc-info {
        border-bottom: 1px solid $color--black;
      }
    }
  }
  .qty-kit {
    padding-left: 3%;
  }
  .checkout {
    &__sidebar {
      .product {
        &__price {
          display: block;
        }
      }
    }
  }
}

.site-bag {
  .kit-container {
    .cart-item {
      &__desc-info {
        margin: 0;
        padding: 0;
        border-bottom: none;
      }
    }
  }
  .cart-product-bundling-price {
    float: #{$ldirection};
  }
}

.field-mobile-template {
  .custom-kit-formatter {
    &__landing-content {
      .custom-kit-formatter {
        &__align-top {
          top: 28%;
        }
      }
    }
  }
}

.contact-us-page {
  .contact-nav {
    li {
      .details {
        .title {
          margin-bottom: 0;
        }
      }
    }
  }
}

// adaptive placeholders
.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    &:focus,
    &.active,
    &.js-label-mode {
      & + label::before {
        transform: translateY(-2.3em) translateY(-1px) scale(0.8, 0.8);
      }
    }
  }
}
