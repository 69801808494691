.field-container {
  @include breakpoint($bp--medium-up) {
    max-width: 910px;
  }
}

.profile-page {
  .profile-info {
    .profile-info__fieldset {
      .form_birthday {
        label {
          display: block;
        }
      }
      .field-container {
        .form_title {
          width: 60%;
          .picker-radio {
            display: inline-block;
          }
        }
      }
    }
  }
}

.field-container--grid {
  &.address-to-use,
  &.date-of-birth {
    .form-item {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--3, 1em) {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
            clear: both;
            .valid_marker,
            .invalid_marker {
              right: 18px;
            }
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
  }
}

.address-overlay-shipping,
.address-overlay-billing {
  margin-bottom: 0.8em;
}

#registration {
  #cboxContent {
    button#cboxClose {
      #{$rdirection}: 10px;
    }
  }
}

.past-purchases-page {
  &__content {
    .past-purchases {
      .notify-status {
        .notify_me {
          margin-top: -2px;
        }
      }
      .product-item {
        &__add-to-cart {
          .product-item {
            &__out-of-stock {
              line-height: 7;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

.favorites-board,
.past-purchases {
  .product-item {
    &__add-to-cart {
      .product-item {
        &__coming-soon,
        &__out-of-stock {
          position: relative;
          @if $fonts_update == true {
            top: 18px;
          } @else {
            top: 30px;
          }
        }
      }
    }
  }
}

.favorites-board {
  .product-item {
    &__add-to-cart {
      .notify_me {
        top: -10px;
      }
    }
  }
}
