// Flex alignment helper classes
.horizontal-align-left,
.horizontal-align-default,
.horizontal-align-start,
.align-items-start {
  align-items: flex-start;
}

.horizontal-align-center,
.align-items-center {
  align-items: center;
}

.horizontal-align-right,
.horizontal-align-end,
.align-items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center,
.justify-default {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
// mobile overrides
div.mobile-horizontal-align-left,
div.mobile-horizontal-align-default,
div.mobile-horizontal-align-start,
div.mobile-align-items-start {
  @include breakpoint($bp--large-down) {
    align-items: flex-start;
  }
}

div.mobile-horizontal-align-center,
div.mobile-align-items-center {
  @include breakpoint($bp--large-down) {
    align-items: center;
  }
}

div.mobile-horizontal-align-right,
div.mobile-horizontal-align-end,
div.mobile-align-items-end {
  @include breakpoint($bp--large-down) {
    align-items: flex-end;
  }
}

div.mobile-justify-start {
  @include breakpoint($bp--large-down) {
    justify-content: flex-start;
  }
}

div.mobile-justify-center,
div.mobile-justify-default {
  @include breakpoint($bp--large-down) {
    justify-content: center;
  }
}

div.mobile-justify-end {
  @include breakpoint($bp--large-down) {
    justify-content: flex-end;
  }
}

// Units helpers
$units: 0 2 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 120 140 160 180 200 768 928 1024
  1280 1366 1440;
$percentage-unit: unquote('%');
$pixel-unit: unquote('px');

@each $value in $units {
  .max-width-#{$value} {
    max-width: #{$value}$percentage-unit;
  }
  .max-width-#{$value}px {
    max-width: #{$value}$pixel-unit;
  }
  .position-#{$rdirection}-#{$value} {
    #{$rdirection}: #{$value}$percentage-unit;
    #{$ldirection}: auto;
  }
  .position-#{$ldirection}-#{$value} {
    #{$ldirection}: #{$value}$percentage-unit;
    #{$rdirection}: auto;
  }
  .padding-#{$value} {
    padding: #{$value}$pixel-unit;
  }
  body .padding-#{$rdirection}-#{$value} {
    padding-#{$rdirection}: #{$value}$percentage-unit;
  }
  body .padding-#{$ldirection}-#{$value}px {
    padding-#{$ldirection}: #{$value}$pixel-unit;
  }
  body .padding-#{$rdirection}-#{$value}px {
    padding-#{$rdirection}: #{$value}$pixel-unit;
  }
  body .padding-#{$ldirection}-#{$value} {
    padding-#{$ldirection}: #{$value}$percentage-unit;
  }
  .padding-top-#{$value} {
    padding-top: #{$value}$percentage-unit;
  }
  .padding-bottom-#{$value} {
    padding-bottom: #{$value}$percentage-unit;
  }
  .padding-top-#{$value}px {
    padding-top: #{$value}$pixel-unit;
  }
  .padding-bottom-#{$value}px {
    padding-bottom: #{$value}$pixel-unit;
  }
  .margin-#{$value} {
    margin: #{$value}$pixel-unit;
  }
  .margin-#{$rdirection}-#{$value} {
    margin-#{$rdirection}: #{$value}$percentage-unit;
  }
  .margin-#{$ldirection}-#{$value} {
    margin-#{$ldirection}: #{$value}$percentage-unit;
  }
  .margin-top-#{$value} {
    margin-top: #{$value}$percentage-unit;
  }
  .margin-top-#{$value}px {
    margin-top: #{$value}$pixel-unit;
  }
  .margin-bottom-#{$value} {
    margin-bottom: #{$value}$percentage-unit;
  }
  .margin-bottom-#{$value}px {
    margin-bottom: #{$value}$pixel-unit;
  }
  .dimension-#{$value} {
    width: #{$value}$pixel-unit;
    height: #{$value}$pixel-unit;
  }
  .max-height-#{$value}vh {
    max-height: #{$value}vh;
  }
  // mobile overrides
  div.mobile-margin-#{$rdirection}-#{$value} {
    @include breakpoint($bp--large-down) {
      margin-#{$rdirection}: #{$value}$percentage-unit;
    }
  }
  div.mobile-margin-#{$ldirection}-#{$value} {
    @include breakpoint($bp--large-down) {
      margin-#{$ldirection}: #{$value}$percentage-unit;
    }
  }
  body div.mobile-padding-#{$rdirection}-#{$value} {
    @include breakpoint($bp--large-down) {
      padding-#{$rdirection}: #{$value}$percentage-unit;
    }
  }
  body div.mobile-padding-#{$ldirection}-#{$value} {
    @include breakpoint($bp--large-down) {
      padding-#{$ldirection}: #{$value}$percentage-unit;
    }
  }
  body div.mobile-padding-#{$rdirection}-#{$value}px {
    @include breakpoint($bp--large-down) {
      padding-#{$rdirection}: #{$value}$pixel-unit;
    }
  }
  body div.mobile-padding-#{$ldirection}-#{$value}px {
    @include breakpoint($bp--large-down) {
      padding-#{$ldirection}: #{$value}$pixel-unit;
    }
  }
  div.mobile-max-width-#{$value} {
    @include breakpoint($bp--large-down) {
      max-width: #{$value}$percentage-unit;
    }
  }
  div.mobile-max-width-#{$value}px {
    @include breakpoint($bp--large-down) {
      max-width: #{$value}$pixel-unit;
    }
  }
  div.mobile-padding-#{$value} {
    @include breakpoint($bp--large-down) {
      padding: #{$value}$pixel-unit;
    }
  }
  div.mobile-padding-top-#{$value} {
    @include breakpoint($bp--large-down) {
      padding-top: #{$value}$percentage-unit;
    }
  }
  div.mobile-padding-bottom-#{$value} {
    @include breakpoint($bp--large-down) {
      padding-bottom: #{$value}$percentage-unit;
    }
  }
  div.mobile-padding-top-#{$value}px {
    @include breakpoint($bp--large-down) {
      padding-top: #{$value}$pixel-unit;
    }
  }
  div.mobile-padding-bottom-#{$value}px {
    @include breakpoint($bp--large-down) {
      padding-bottom: #{$value}$pixel-unit;
    }
  }
  div.mobile-margin-top-#{$value} {
    @include breakpoint($bp--large-down) {
      margin-top: #{$value}$percentage-unit;
    }
  }
  div.mobile-margin-bottom-#{$value} {
    @include breakpoint($bp--large-down) {
      margin-bottom: #{$value}$percentage-unit;
    }
  }
  div.mobile-margin-top-#{$value}px {
    @include breakpoint($bp--large-down) {
      margin-top: #{$value}$pixel-unit;
    }
  }
  div.mobile-margin-bottom-#{$value}px {
    @include breakpoint($bp--large-down) {
      margin-bottom: #{$value}$pixel-unit;
    }
  }
}

// Mantle helpers
.mantle-media-asset {
  width: 100%;
  height: 100%;
  img,
  picture,
  video {
    max-width: 100%;
    width: 100%;
  }
}

$ratios: (
  '1-1': '1/1',
  '2-1': '2/1',
  '3-1': '3/1',
  '3-4': '3/4',
  '4-1': '4/1',
  '4-3': '4/3',
  '7-1': '7/1',
  '9-16': '9/16',
  '16-9': '16/9'
);
@mixin get-aspect-ratio($assetTag, $assetType, $class) {
  // we need to create the classes in a specific order to get mobile / pc VS mobile work as expected.
  #{$assetTag}:not([width][height]) {
    @each $ratio, $ratioValue in $ratios {
      .#{$assetType}-#{$class}-#{$ratio},
      .#{$assetType}-#{$class}-#{$ratio}.mantle-media-asset & {
        aspect-ratio: auto #{$ratioValue};
      }
    }
    @each $ratio, $ratioValue in $ratios {
      .mobile-#{$assetType}-#{$class}-#{$ratio},
      .mobile-#{$assetType}-#{$class}-#{$ratio}.mantle-media-asset & {
        aspect-ratio: auto #{$ratioValue};
      }
    }
    @each $ratio, $ratioValue in $ratios {
      .#{$assetType}-#{$class}-#{$ratio},
      .#{$assetType}-#{$class}-#{$ratio}.mantle-media-asset & {
        @include breakpoint($bp--larger-up) {
          aspect-ratio: auto #{$ratioValue};
        }
      }
    }
  }
}
@include get-aspect-ratio('img', 'image', 'aspect-ratio');
@include get-aspect-ratio('video', 'video', 'aspect-ratio');

// Text Alignment
.text-align--#{$ldirection} {
  text-align: $ldirection;
}

.text-align--center {
  text-align: center;
}
.text-align--#{$rdirection} {
  text-align: $rdirection;
}
// mobile overrides
div.mobile-text-align--#{$ldirection} {
  @include breakpoint($bp--large-down) {
    text-align: $ldirection;
  }
}

div.mobile-text-align--center {
  @include breakpoint($bp--large-down) {
    text-align: center;
  }
}
div.mobile-text-align--#{$rdirection} {
  @include breakpoint($bp--large-down) {
    text-align: $rdirection;
  }
}
// Text Color (mobile overrides)
div.mobile-text-color--black,
div.mobile-text-color--black a {
  @include breakpoint($bp--large-down) {
    color: $color--black;
  }
}

div.mobile-text-color--white,
div.mobile-text-color--white a {
  @include breakpoint($bp--large-down) {
    color: $color--white;
  }
}

.text-color--white,
.text-color--white a {
  color: $color--white;
}

.text-color--black,
.text-color--black a {
  color: $color--black;
}

.background--black {
  background-color: $color--black;
}
.background--white {
  background-color: $color--white;
}
.position-top-left {
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
}
.position-top-right {
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
}
.position-bottom-left {
  position: absolute;
  #{$ldirection}: 0;
  bottom: 0;
}
.position-bottom-right {
  position: absolute;
  #{$rdirection}: 0;
  bottom: 0;
}
.hidden--mobile {
  display: none;
  @include breakpoint($bp--xlarge-up) {
    display: block;
  }
}
.hidden--pc {
  @include breakpoint($bp--xlarge-up) {
    display: none;
  }
}
.invisible {
  visibility: hidden;
}
